(function() {
  "use strict";
  window.addEventListener('load',function(){
 
    //scrolling text animation functions
    if(this.document.querySelector('.background-text')){
    const backgroundText = document.querySelector('.background-text');
    let backgroundTextParent;
    if(backgroundText){
       backgroundTextParent = backgroundText.parentNode

    }
    const clonedBackgroundText = backgroundText.cloneNode(true);
    clonedBackgroundText.classList.add('background-text-repeat')
    backgroundTextParent.appendChild(clonedBackgroundText)

    backgroundText.style.animationPlayState = "running"
    clonedBackgroundText.style.animationPlayState = "running"
  }

  const getTogglePauseScroll = (toggle, feed) => () => {
    const pimFeed = document.querySelector(feed);
    pimFeed.children[0].classList.toggle('paused', toggle);
    pimFeed.children[1].classList.add('paused', toggle);
  }

  // pim feed animation functions
    if(this.document.querySelector('.pim-feed')){
      const pimFeed = document.querySelector('.pim-feed');
      pimFeed.addEventListener('click', getTogglePauseScroll(true, '.pim-feed'));
      pimFeed.addEventListener('mouseenter', getTogglePauseScroll(true, '.pim-feed'));
      pimFeed.addEventListener('mouseleave', getTogglePauseScroll(false, '.pim-feed'));
      
      const ccobFeed = document.querySelector('.ccob');
      ccobFeed.addEventListener('click', getTogglePauseScroll(true, ".ccob"));
      ccobFeed.addEventListener('mouseenter', getTogglePauseScroll(true, '.ccob'));
      ccobFeed.addEventListener('mouseleave', getTogglePauseScroll(false, '.ccob'));
      
      const primerFeed = document.querySelector('.primer');
      primerFeed.addEventListener('click', getTogglePauseScroll(true, ".primer"));
      primerFeed.addEventListener('mouseenter', getTogglePauseScroll(true, '.primer'));
      primerFeed.addEventListener('mouseleave', getTogglePauseScroll(false, '.primer'));
    }


    // Compose pim message landing page functions
    if(this.document.querySelector('.pim-counter-display') && this.document.querySelector('.pim-character-count')){
      
      const pimCounter = document.querySelector('.pim-counter-display');
      const pimCharacterCount = document.querySelector('.pim-character-count');
      const pimTextArea = document.querySelector('.js-pim-message textarea');
      const pimQuantity = document.querySelector('.js-pim-quantity input');
      const priceDisplay = document.querySelector('.pim-price-info');
      const pimPrice = document.createElement('p');
      pimPrice.classList.add('pim-price');
      pimPrice.innerHTML = `$10.00`;
      priceDisplay.appendChild(pimPrice);

      const updateCounters = function(){
        let priceDisplay = document.querySelector('.pim-price');
        let price = 10;
        let pimLength = Math.floor((pimTextArea.value.length + 199 ) / 200);
        let priceSum = ( (price * Math.min(5, pimLength))).toFixed(2)

        pimQuantity.value = pimLength;
        
        priceDisplay.innerHTML = `$${priceSum}`;

        pimCounter.innerHTML = `${pimLength }/6`;
        if( pimTextArea.value.length >=1 && pimTextArea.value.length < 200){
          pimCounter.innerHTML = `1/6`;
        }
        pimCharacterCount.innerHTML = `${pimTextArea.value.length}/1200`;
      };
      pimTextArea.addEventListener('input', updateCounters)
      updateCounters();            
    }
})
})();
